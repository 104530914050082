<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndAddNew)">
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.companyCode')"
                      :items="companyCodeList"
                      item-text="companycode"
                      item-value="companycode"
                      :loading="companyCodeLoading"
                      v-model="selectedCompanyCode"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.plantCode')"
                      :items="plantCodeList"
                      :disabled="saving || !selectedCompanyCode"
                      :loading="fetchingPlantCode"
                      item-text="plantcode"
                      item-value="plantcode"
                      v-model="selectedPlantCode"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.batteryType')"
                      :items="partMatrixListData"
                      item-text="batterytypedescription"
                      item-value="batterycode"
                      :loading="fetchingPartMatrix"
                      :disabled="saving || !selectedPlantCode"
                      v-model="selectedBatteryType"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.model')"
                      :items="modelList"
                      item-text="model"
                      item-value="model"
                      :disabled="saving || !selectedBatteryType"
                      v-model="selectedModel"
                      @change="fetchPartByModel"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.variant')"
                      :items="variantList"
                      :disabled="saving || !selectedModel"
                      :loading="fetchingMatrix"
                      item-text="variant"
                      item-value="variant"
                      v-model="selectedVariant"
                      @change="filterCustomer"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.customer')"
                      :items="uniqueCustomerList"
                      item-text="customer"
                      item-value="customer"
                      :disabled="saving || !selectedVariant"
                      v-model="selectedCustomer"
                      @change="filterLine"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.stage')"
                      :items="stageCodeList"
                      item-text="stagedescription"
                      item-value="stagecode"
                      :disabled="saving || !selectedCustomer"
                      v-model="selectedStage"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.line')"
                      :items="lineList"
                      :loading="fetchingPartMatrix"
                      item-text="linename"
                      item-value="linename"
                      :disabled="saving || !selectedStage"
                      v-model="selectedLine"
                      @change="filterPart"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      :label="$t('assemblyPlanningTaco.remark')"
                      outlined
                      v-model="plan.remark"
                      required
                    ></v-text-field>
                    <!-- <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.partname')"
                      :items="partList"
                      item-text="partname"
                      item-value="partname"
                      :disabled="saving || !selectedLine"
                      v-model="selectedPart"
                      return-object
                      @change="setPlanParameters"
                    ></v-autocomplete> -->
                  </v-col>
                </v-row>
                <div class="title mb-2">
                  {{ $t('assemblyPlanningTaco.operationalParams') }}
                  <v-btn
                    small
                    outlined
                    class="text-none"
                    :disabled="!selectedMatrix || saving"
                    @click="editParams = !editParams"
                    color="primary"
                  >
                    <template v-if="!editParams">
                      <v-icon left small>mdi-pencil-outline</v-icon>
                      {{ $t('assemblyPlanningTaco.editButton') }}
                    </template>
                    <template v-else>
                      <v-icon left small>mdi-check</v-icon>
                      {{ $t('assemblyPlanningTaco.doneButton') }}
                    </template>
                  </v-btn>
                </div>
                <v-row v-for="macdetails in plan.machinedetails" :key="macdetails.machinename">
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.machine')"
                        type="name"
                        disabled
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        v-model="macdetails.machinename"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="cycletime"
                      rules="required|min_value:1"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.cycletime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        :suffix="$t('assemblyPlanningTaco.secs')"
                        v-model="macdetails.stdcycletime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="delaytime"
                      rules="required|min_value:0"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.delayTime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cached"
                        outlined
                        :suffix="$t('assemblyPlanningTaco.secs')"
                        v-model="macdetails.delaytime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="title mt-4 mb-2">
                  {{ $t('assemblyPlanningTaco.planDetails') }}
                </div>
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="quantity"
                      :rules="`required|numeric|min_value:1|multiple_of:${plan.activecavity}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.quantity')"
                        type="number"
                        :disabled="saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-package-variant-closed"
                        outlined
                        :suffix="$t('assemblyPlanningTaco.pieces')"
                        v-model="plan.plannedquantity"
                        hide-details="auto"
                        @change="onQuantityChange"
                        :hint="`Should be multiple of active cavity(${plan.activecavity})`"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="scheduledstart"
                      rules="required|greater_than_now"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.scheduledStartTime')"
                        type="datetime-local"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar-check-outline"
                        outlined
                        v-model="plan.scheduledstart"
                        hide-details="auto"
                        :disabled="saving"
                        @change="fetchEstimatedEnd"
                      >
                        <template #append-outer>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                v-text="'$info'"
                                v-on="on"
                                v-bind="attrs"
                              ></v-icon>
                            </template>
                            <ul>
                              <li>
                                Scheduled start time is only used in calculating
                                planned vs actual performance.
                              </li>
                              <li>
                                Updating scheduled start time will not affect
                                the execution order of the plan.
                              </li>
                              <li>
                                Plans are picked automatically in FIFO fashion.
                              </li>
                              <li>
                                To update the execution order, please visit
                                <strong>re-order plans section</strong>.
                              </li>
                            </ul>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanningTaco.markAsStar')"
                      :disabled="saving"
                      v-model="plan.starred"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanningTaco.markAsTrial')"
                      :disabled="saving"
                      v-model="plan.trial"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  {{ $t('assemblyPlanningTaco.estimatedEnd') }}
                  <strong>{{ estimatedEndDisplay }}</strong>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('assemblyPlanningTaco.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  class="text-none"
                  :disabled="savingAndNew || !selectedPart || !plan.plannedquantity || !plan.remark"
                  :loading="savingAndExit"
                  @click="onSaveAndExit"
                >
                  {{ $t('assemblyPlanningTaco.saveExit') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-none"
                  :disabled="savingAndExit || !selectedPart || !plan.plannedquantity
                    || !plan.remark"
                  :loading="savingAndNew"
                  type="submit"
                >
                  {{ $t('assemblyPlanningTaco.saveAddNew') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
// import { sortArray } from '@shopworx/services/util/sort.service';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'AddPlan',
  data() {
    return {
      headers: [
        { text: this.$t('assemblyPlanningTaco.part'), value: 'partname' },
        { text: this.$t('assemblyPlanningTaco.activeCavity'), value: 'activecavity' },
        { text: this.$t('assemblyPlanningTaco.quantity'), value: 'plannedquantity' },
      ],
      editParams: false,
      savingAndExit: false,
      savingAndNew: false,
      fetchingMatrix: false,
      fetchingPlantCode: false,
      fetchingPartMatrix: false,
      fetchingCompanyCode: false,
      companyCodeLoading: false,
      stageCodeLoading: false,
      variantList: [],
      plantCodeList: [],
      companyCodeList: [],
      stageCodeList: [],
      uniqueCustomerList: [],
      lineList: [],
      partList: [],
      partMatrixList: [],
      selectedModel: null,
      selectedCompanyCode: null,
      selectedPlantCode: null,
      selectedVariant: null,
      selectedCustomer: null,
      selectedLine: null,
      selectedStage: null,
      selectedPart: null,
      selectedBatteryType: null,
      partMatrixListData: [],
      plan: {},
      // remark: '',
    };
  },
  computed: {
    ...mapState('assemblyPlanningTaco', ['parts', 'partMatrix', 'customerList']),
    ...mapGetters('assemblyPlanningTaco', ['selectedAsset', 'partMatrixTags']),
    ...mapGetters('helper', ['locale']),
    saving() {
      return this.savingAndExit || this.savingAndNew;
    },
    modelList() {
      return this.parts;
    },
    customers() {
      return this.uniqueCustomerList;
    },
    estimatedEndDisplay() {
      let res = '';
      if (this.plan.scheduledend) {
        res = formatDate(
          new Date(this.plan.scheduledend),
          'PPpp',
          { locale: this.locale },
        );
      }
      return res;
    },
    selectedMatrix() {
      if (this.selectedPart && this.selectedLine && this.selectedModel) {
        const partMatrix = this.partMatrixList.filter((matrix) => (
          matrix.partname === this.selectedPart.partname
          && matrix.linename === this.selectedLine
          && matrix.batterycode === this.selectedBatteryType
          && matrix.model === this.selectedModel
        ));
        return partMatrix.sort((a, b) => a.sequence - b.sequence);
      }
      return null;
    },
    shots() {
      if (this.plan.plannedquantity && this.plan.activecavity) {
        return +this.plan.plannedquantity / +this.plan.activecavity;
      }
      return 0;
    },
  },
  async created() {
    await this.fetchCustomerList();
    this.initPlan();
    this.companyCodeLoading = true;
    this.companyCodeList = await this.fetchCompanyCode();
    this.companyCodeLoading = false;
    this.fetchingPlantCode = true;
    this.plantCodeList = await this.fetchPlantCode();
    this.fetchingPlantCode = false;
    this.fetchingPartMatrix = true;
    this.partMatrixListData = await this.fetchPartMatrixData();
    this.fetchingPartMatrix = false;
    this.stageCodeLoading = true;
    this.stageCodeList = await this.fetchStageCode();
    this.stageCodeLoading = false;
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('assemblyPlanningTaco', [
      'fetchPartMatrix',
      'getScheduledEnd',
      'fetchLastPlan',
      'createPlans',
      'fetchModel',
      'fetchCompanyCode',
      'fetchPlantCode',
      'fetchCustomerList',
      'fetchPartMatrixData',
      'fetchStageCode',
    ]),
    initPlan() {
      this.plan = {
        assetid: 0,
        partname: '',
        linename: '',
        activecavity: 1,
        machinedetails: [],
        plannedquantity: 0,
        scheduledstart: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: '',
        starred: false,
        trial: false,
        sortindex: 0,
        status: 'notStarted',
        // remark: '',
      };
    },
    clear() {
      this.initPlan();
      this.editParams = false;
      this.variantList = [];
      // this.customers = [];
      this.companyCodeList = [];
      this.stageCodeList = [];
      this.plantCodeList = [];
      this.partMatrixListData = [];
      this.modelList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedCompanyCode = null;
      this.selectedPlantCode = null;
      this.selectedBatteryType = null;
      this.selectedModel = null;
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      this.selectedStage = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async fetchPartByModel() {
      this.initPlan();
      this.variantList = [];
      // this.customers = [];
      this.lineList = [];
      this.partList = [];
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      this.selectedStage = null;
      if (this.selectedModel) {
        this.fetchingMatrix = true;
        this.variantList = await this.fetchModel({ model: this.selectedModel });
        this.fetchingMatrix = false;
      }
    },
    filterCustomer() {
      this.initPlan();
      // this.customers = [];
      this.lineList = [];
      this.partList = [];
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      if (this.selectedVariant) {
        this.uniqueCustomerList = this.variantList
          .filter((variant) => variant.variant === this.selectedVariant);
      }
    },
    filterLine() {
      this.initPlan();
      this.lineList = [];
      this.partList = [];
      this.selectedLine = null;
      this.selectedPart = null;
      if (this.selectedCustomer) {
        // this.lineList = this.variantList;
        this.lineList = this.variantList
          .filter((variant) => variant.variant === this.selectedVariant);
      }
    },
    filterPart() {
      this.initPlan();
      this.partList = [];
      this.selectedPart = null;
      if (this.selectedLine && this.selectedModel) {
        this.partList = this.lineList
          .filter((line) => line.linename === this.selectedLine);
        this.partMatrixList = this.partList;
        [this.selectedPart] = this.partList;
        this.setPlanParameters();
      }
    },
    async setPlanParameters() {
      this.initPlan();
      if (this.selectedPart && this.selectedLine && this.selectedModel) {
        this.plan.partname = this.selectedPart.partname;
        this.plan.linename = this.selectedLine;
        // this.plan.modulecode = this.selectedModuleCode;
        // this.plan.activecavity = 1;
        this.plan.machinedetails = [];
        this.selectedMatrix.forEach((part) => {
          const {
            machinename,
            stdcycletime,
            linename,
            delaytime,
            drawingno,
            drawingversionno,
          } = part;
          this.plan.machinedetails.push({
            machinename,
            stdcycletime,
            linename,
            delaytime,
            drawingno,
            drawingversionno,
          });
        });
      }
    },
    async fetchEstimatedEnd() {
      if (this.plan.plannedquantity && this.plan.scheduledstart) {
        const machineCount = this.plan.machinedetails.length;
        const totalStdCycletime = this.plan.machinedetails.reduce((acc, cur) => {
          acc += (+cur.stdcycletime * 1000);
          return acc;
        }, 0);
        let ctAvg = 0;
        if (machineCount !== 0) {
          ctAvg = Math.floor(totalStdCycletime / machineCount);
        }
        const runTime = ((+this.plan.plannedquantity - 1)
          * ctAvg) + totalStdCycletime;
        this.plan.linestdcycletime = (runTime / 1000);
        this.plan.scheduledend = await this.getScheduledEnd({
          start: new Date(this.plan.scheduledstart).getTime(),
          duration: runTime,
        });
      } else {
        this.plan.scheduledend = '';
      }
    },
    onQuantityChange() {
      this.fetchEstimatedEnd();
    },
    async setSortIndex() {
      const lastPlan = await this.fetchLastPlan();
      if (lastPlan && lastPlan.sortindex) {
        this.plan.sortindex = lastPlan.sortindex + 100;
      } else {
        this.plan.sortindex = 100;
      }
    },
    exit() {
      this.clear();
      this.selectedPart = null;
      this.$router.push({ name: 'assemblyPlanningTaco' });
    },
    async save() {
      await this.setSortIndex();
      const lastPlan = await this.fetchLastPlan();
      let lineplanid;
      if (lastPlan && lastPlan.lineplanid) {
        lineplanid = Number(lastPlan.lineplanid) + 1;
      } else {
        lineplanid = 1;
      }
      // const matrixTags = this.partMatrixTags(this.plan.assetid);
      // const partMatrix = matrixTags.reduce((acc, cur) => {
      //   acc[cur.tagName] = this.selectedMatrix[cur.tagName];
      //   return acc;
      // }, {});
      const payload = [];
      let customerDetailInfo = this.customerList
        .filter((item) => item.customername === this.selectedCustomer);
      customerDetailInfo = this.deleteRawKeys(customerDetailInfo[0]);
      // eslint-disable-next-line
      for (let i = 0; i < this.selectedMatrix.length; i += 1) {
        const obj = {
          machinename: this.selectedMatrix[i].machinename,
          stdcycletime: this.selectedMatrix[i].stdcycletime,
          linename: this.selectedMatrix[i].linename,
          delaytime: this.selectedMatrix[i].delaytime,
          assetid: this.selectedPart.assetid,
          partname: this.selectedPart.partname,
          activecavity: 1,
          plannedquantity: this.plan.plannedquantity,
          scheduledstart: new Date(this.plan.scheduledstart).getTime(),
          scheduledend: this.plan.scheduledend,
          starred: this.plan.starred,
          trial: this.plan.trial,
          sortindex: this.plan.sortindex,
          status: this.plan.status,
          companycode: this.selectedCompanyCode.companycode,
          companymodulecode: this.selectedCompanyCode.companymodulecode,
          plantcode: this.selectedPlantCode,
          batterytypedescription: this.selectedBatteryType,
          model: this.selectedModel,
          variant: this.selectedVariant,
          customer: this.selectedCustomer,
          modulecode: this.selectedMatrix[i].modulecode,
          moduledescription: this.selectedMatrix[i].moduledescription,
          stagecode: this.selectedStage.stagecode,
          stagedescription: this.selectedStage.stagedescription,
          remark: this.plan.remark,
          linestdcycletime: this.plan.linestdcycletime,
          drawingno: this.selectedMatrix[i].drawingno,
          drawingversionno: this.selectedMatrix[i].drawingversionno,
          familyName: 'family',
          // ...partMatrix,
          lineplanid,
          ...customerDetailInfo,
        };
        payload.push(obj);
      }
      const created = await this.createPlans(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_CREATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_CREATING_PLAN',
        });
      }
      return created;
    },
    async onSaveAndAddNew() {
      this.savingAndNew = true;
      const created = await this.save();
      if (created) {
        this.clear();
        this.selectedPart = null;
      }
      this.savingAndNew = false;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.savingAndExit = true;
        const created = await this.save();
        if (created) {
          this.exit();
        }
        this.savingAndExit = false;
      }
    },
    deleteRawKeys(data = {}) {
      delete data._id;
      delete data.assetid;
      delete data.createdTimestamp;
      delete data.customerId;
      delete data.elementId;
      delete data.elementName;
      delete data.modifiedtimestamp;
      delete data.questions;
      delete data.siteId;
      delete data.userId;
      return data;
    },
  },
};
</script>
